import React, { useState, useEffect } from "react"
import QRCode from "qrcode.react"
import useOneGameStore from "../../data/oneGameStore"
import useTemplateStore from "../../data/templateStore"
import useCategoryStore from "../../data/categoryStore"
import { PiUsersThreeFill } from "react-icons/pi"
import { BsFillRewindFill, BsFillPlayFill } from "react-icons/bs"
import { MdScoreboard } from "react-icons/md"
import { FaEyeSlash } from "react-icons/fa6"
import Modal from "../Modal/Modal"
import CategorySelection from "../Game/slides/CategorySelection"
import { Link } from "react-router-dom"
import { RiSlideshow2Fill } from "react-icons/ri"
import { TbHelpHexagonFilled } from "react-icons/tb"
import useTeamStore from "../../data/teamStore"

const ControlsPanel = ({
    game,
    setCurrentQuestion,
    showAnswer,
    currentQuestion,
    setShowAnswer,
    showControls,
    setShowControls,
    admin,
    showTeams,
    setShowTeams,
    setCurrentTeam,
    setShowScoreboard,
    setCurrentRound,
    currentRound,
    finalizeScores,
    processCurrentRound,
    setScoringRound,
    handledGameOpenedForFirstTime,
}) => {
    const [showQR, setShowQR] = useState(false)
    const [previousSlide, setPreviousSlide] = useState(null) // State to store the previous slide
    const [isScoreboardDisplayed, setIsScoreboardDisplayed] = useState(false) // State to track if scoreboard is displayed
    const [answerSlideQuestion, setAnswerSlideQuestion] = useState(1)
    const template = useTemplateStore(state => state.template)
    const categories = useCategoryStore(state => state.categories)
    const localGame = JSON.parse(localStorage.getItem("smt-game"))
    const teams = useTeamStore(state => state.teams)
    const currentRoundOne = useOneGameStore(state => state.getCurrentRound())
    const [showHelp, setShowHelp] = useState(true)
    const [top3, setTop3] = useState([])
    const [firstPlace, setFirstPlace] = useState(null)
    const [secondPlace, setSecondPlace] = useState(null)
    const [thirdPlace, setThirdPlace] = useState(null)

    const processSlideTitle = slide => {
        if (useOneGameStore.getState().isAnswerSlide()) {
            if (showAnswer) {
                return currentQuestion ===
                    useOneGameStore.getState().questionsPerRound - 1
                    ? "Scoreboard"
                    : "Next Question"
            } else {
                return "Show Answer"
            }
        }
        if (slide === "Rules") {
            return "How It Works (cont.)"
        } else if (slide === "HowItWorks") {
            return "How It Works"
        } else if (slide === "Signup") {
            return "Website Stats"
        } else if (slide === "Scoreboard" && game.currentSlide === 94) {
            return "End"
        }
        return slide ? slide.split(/(?=[A-Z])/).join(" ") : slide
    }

    const processDropDowntitle = title => {
        if (title === "Rules") {
            return "How It Works (cont.)"
        } else if (title === "HowItWorks") {
            return "How It Works"
        } else if (title === "Signup") {
            return "Website Stats"
        }

        return title
    }

    const toggleScoreboard = () => {
        if (!isScoreboardDisplayed) {
            setPreviousSlide(game.currentSlide)
            useOneGameStore.getState().setSlide(95)
        } else {
            useOneGameStore.getState().setSlide(previousSlide)
            setPreviousSlide(null)
        }
        setIsScoreboardDisplayed(!isScoreboardDisplayed)
    }

    const checkIfTop3IsSet = () => {
        return (
            game?.teams?.some(team => team.position > 0) &&
            game?.teams?.find(team => team.position === 1)?.team ===
                firstPlace &&
            game?.teams?.find(team => team.position === 2)?.team ===
                secondPlace &&
            game?.teams?.find(team => team.position === 3)?.team === thirdPlace
        )
    }

    const handleNextSlide = () => {
        if (game.currentSlide === 0 && !game.opened) {
            handledGameOpenedForFirstTime()
            window.open(`/slideshow/${game.id}`, "_blank")
            return
        }
        if (useOneGameStore.getState().isAnswerSlide()) {
            if (showAnswer) {
                setShowAnswer(false)
                if (
                    currentQuestion <
                    useOneGameStore.getState().questionsPerRound - 1
                ) {
                    setCurrentQuestion(currentQuestion + 1)
                } else {
                    setCurrentQuestion(0)
                    useOneGameStore.getState().nextSlide()
                }
            } else {
                setShowAnswer(true)
            }
        } else if (
            useOneGameStore.getState().nextSlideTitle() === "CategorySelection"
        ) {
            calculateChoosingTeam()
            setTimeout(() => {
                useOneGameStore.getState().nextSlide()
            }, 500)
        } else if (
            game.currentSlide === useOneGameStore.getState().lastSlide()
        ) {
            finalizeScores()
        } else if (game.currentSlide === 95) {
            calculateTop3()
            useOneGameStore
                .getState()
                .setSlide(useOneGameStore.getState().lastSlide())
        } else {
            setCurrentQuestion(0)
            const slideTitle = useOneGameStore.getState().nextSlideTitle()
            if (slideTitle === "CategorySelection") {
                setCurrentRound(currentRound + 1)
                setScoringRound(false)
            } else if (slideTitle === "Rotating") {
                setCurrentRound(currentRound)
                setScoringRound(true)
            }
            useOneGameStore.getState().nextSlide()
        }
    }

    const calculateChoosingTeam = (additive = 1) => {
        // time to pick the team and assign them to the round in the game object
        if (
            localGame?.rounds?.find(r => r.round === currentRound + additive)
                ?.team &&
            additive === 1
        )
            return

        const teams = localGame?.teams
        const randomTeam = teams[Math.floor(Math.random() * teams.length)]

        const teamTotals =
            teams
                ?.filter(team => {
                    return localGame?.rounds?.every(
                        round => round.team !== team.team
                    )
                })
                .map(team => {
                    return {
                        team: team?.team,
                        displayName: team?.displayName,
                        score: team?.scores?.reduce((acc, score) => {
                            return (
                                acc +
                                score.questions?.reduce(
                                    (acc, question) =>
                                        acc + (question.correct ? 1 : 0),
                                    0
                                )
                            )
                        }, 0),
                    }
                }) || []

        const lowestScoringTeam =
            teamTotals.length > 0
                ? teamTotals?.reduce((acc, team) => {
                      return acc.score < team.score ? acc : team
                  }, teamTotals[0])
                : randomTeam

        const currentSlide = game?.currentSlide
        const currRound = useOneGameStore
            .getState()
            .getCurrentRoundBySlide(currentSlide)

        const newRounds = localGame.rounds.map(round => {
            if (round.round === currRound + additive) {
                return {
                    ...round,
                    team: lowestScoringTeam.team,
                }
            }
            return round
        })

        return useOneGameStore.getState().updateGame({
            rounds: newRounds,
        })
    }

    const handleSlideDropdownChange = e => {
        setShowAnswer(false)
        setCurrentQuestion(0)
        setShowTeams(false)
        setScoringRound(true)
        setCurrentRound(
            useOneGameStore.getState().getCurrentRoundBySlide(e.target.value)
        )
        if (
            useOneGameStore.getState().getSlideTitle(e.target.value) ===
            "CategorySelection"
        ) {
            calculateChoosingTeam()
            setScoringRound(false)
        } else if (
            useOneGameStore.getState().getSlideTitle(e.target.value) ===
            "Rotating"
        ) {
            setScoringRound(true)
        } else if (
            useOneGameStore.getState().getSlideTitle(e.target.value) ===
            "Category"
        ) {
            setScoringRound(true)
        } else if (
            useOneGameStore.getState().getSlideTitle(e.target.value) === "End"
        ) {
            calculateTop3()
        }

        useOneGameStore.getState().setSlide(e.target.value)
    }

    const handlePrevSlide = () => {
        if (useOneGameStore.getState().isAnswerSlide()) {
            if (showAnswer) {
                setShowAnswer(false)
            } else {
                if (currentQuestion > 0) {
                    setCurrentQuestion(currentQuestion - 1)
                    setShowAnswer(true)
                } else {
                    setCurrentQuestion(0)
                    useOneGameStore.getState().prevSlide()
                }
            }
        } else if (
            game.currentSlide === useOneGameStore.getState().lastSlide()
        ) {
            useOneGameStore.getState().setSlide(95)
        } else {
            setCurrentQuestion(0)
            useOneGameStore.getState().prevSlide()
        }
    }

    const getNextSlidePreview = () => {
        const nextSlideIndex = game.currentSlide + 1
        const nextSlide = useOneGameStore.getState().slides[nextSlideIndex]
        if (template?.questions) {
            const currentRound = useOneGameStore.getState().getCurrentRound()
            if (nextSlide === "Question") {
                const tmpCurrentQuestion =
                    nextSlide === "Question"
                        ? useOneGameStore.getState().getCurrentQuestion()
                        : currentQuestion

                const roundCategory = game.rounds[currentRound - 1]?.category
                let questionText = ""
                let answerText = ""
                if (template.questions[roundCategory] !== undefined) {
                    questionText =
                        template.questions[roundCategory][
                            tmpCurrentQuestion - 1
                        ]?.question

                    answerText =
                        template.questions[roundCategory][
                            tmpCurrentQuestion - 1
                        ]?.answer
                }

                const textLength = questionText?.length
                let fontSize = "1.25rem" // Default font size
                if (textLength > 150) {
                    fontSize = "0.95rem" // Smaller font for long text
                } else if (textLength > 100) {
                    fontSize = "1rem"
                } else if (textLength > 50) {
                    fontSize = "1.25rem"
                }

                return (
                    <>
                        
                    </>
                )
            }
        }
        return `Next: ${processSlideTitle(nextSlide)}`
    }

    const getCurrentSlidePreview = () => {
        const currentSlide =
            useOneGameStore.getState().slides[game.currentSlide]
        if (template?.questions) {
            const currentRound = useOneGameStore.getState().getCurrentRound()
            if (currentSlide === "Question" || currentSlide === "Answers") {
                const tmpCurrentQuestion =
                    currentSlide === "Question"
                        ? useOneGameStore.getState().getCurrentQuestion()
                        : currentQuestion + 2

                const roundCategory = game.rounds[currentRound - 1]?.category
                let questionText = ""
                let answerText = ""
                let nextQuestionText = ""
                if (template.questions[roundCategory] !== undefined) {
                    questionText =
                        template?.questions[roundCategory][
                            tmpCurrentQuestion - 2
                        ]?.question || ""

                    answerText =
                        template?.questions[roundCategory][
                            tmpCurrentQuestion - 2
                        ]?.answer || ""
                }

                if (template.questions[roundCategory] !== undefined) {
                    nextQuestionText =
                        template?.questions[roundCategory][
                            tmpCurrentQuestion - 1
                        ]?.question || ""
                }

                return (
                    <>
                        <span className="current-question-preview black-text">
                            <strong>Current Question:</strong> {questionText}
                        </span>
                        {currentSlide === "Answers" && (
                            <span className="current-answer-preview black-text">
                                <strong>Answer:</strong> {answerText}
                            </span>
                        )}
                        {currentSlide === "Question" &&
                            nextQuestionText !== "" && (
                                <span className="next-question-preview black-text">
                                    <strong>Next Question:</strong>{" "}
                                    {nextQuestionText}
                                </span>
                            )}
                    </>
                )
            }
        }
        return ""
    }

    const getNextButtonLabel = () => {
        if (game.status === "finalized") {
            return "Game & Scores Finalized"
        }
        if (!game?.opened && game.currentSlide === 0) {
            return "Open Slideshow"
        }
        if (game.currentSlide === 95) {
            return checkTopThreeForTie() ? "Tie-Breaker" : "End"
        }
        if (game.currentSlide === useOneGameStore.getState().lastSlide()) {
            const untouchedScores = game.teams
                .map(team => team.scores.filter(score => !score.touched).length)
                .reduce((acc, val) => acc + val, 0)
            if (untouchedScores === 0) {
                return "Finalize Scores"
            } else {
                return `Finish Scoring (${untouchedScores})`
            }
        }
        return useOneGameStore.getState().determineDisabled()
            ? useOneGameStore.getState().determineDisabled().text
            : useOneGameStore.getState().isQuestionSlide()
            ? getNextSlidePreview()
            : processSlideTitle(
                  useOneGameStore.getState().slides[
                      useOneGameStore.getState().game.currentSlide + 1
                  ]
              )
    }

    const handleCategoryClick = (categoryId, teamId = 0) => {
        // get current round
        const currentRound = useOneGameStore.getState().getCurrentRound()
        useOneGameStore
            .getState()
            .assignCategory(currentRound, categoryId, teamId)
        useOneGameStore.getState().nextSlide()
        setScoringRound(true)
    }

    const turnRoundIntoCSSCategoryName = () => {
        const categoryName = categories.find(
            c =>
                c.id ===
                game.rounds[useOneGameStore.getState().getCurrentRound() - 1]
                    ?.category
        )?.name

        return categoryName?.toLowerCase().replace(" ", "-")
    }

    const calculateTop3 = () => {
        if (game.teams.filter(team => team.position > 0).length === 3) {
            setTop3(
                game.teams
                    .filter(team => team.position > 0)
                    .sort((a, b) => a.position - b.position)
                    .map((team, index) => {
                        if (index === 0) {
                            setFirstPlace(team.team)
                        } else if (index === 1) {
                            setSecondPlace(team.team)
                        } else if (index === 2) {
                            setThirdPlace(team.team)
                        }
                        return {
                            team: team.team,
                            total: team.scores.reduce(
                                (acc, score) =>
                                    acc +
                                    score.questions.filter(q => q.correct)
                                        .length,
                                0
                            ),
                        }
                    })
            )
        } else {
            setTop3(
                game.teams
                    .map(team => {
                        return {
                            team: team.team,
                            total: team.scores.reduce(
                                (acc, score) =>
                                    acc +
                                    score.questions.filter(q => q.correct)
                                        .length,
                                0
                            ),
                        }
                    })
                    .sort((a, b) => b.total - a.total)
                    .slice(0, 3)
            )
        }
    }

    const checkTopThreeForTie = () => {
        if (
            top3?.length < 3 &&
            game?.teams?.length > 0 &&
            top3?.length < game?.teams?.length
        ) {
            calculateTop3()
        }
        if (
            top3[0]?.total === top3[1]?.total ||
            top3[1]?.total === top3[2]?.total
        ) {
            return true
        }
        return false
    }

    const validTop3 = () => {
        if (
            firstPlace &&
            secondPlace &&
            thirdPlace &&
            firstPlace !== secondPlace &&
            firstPlace !== thirdPlace &&
            secondPlace !== thirdPlace
        ) {
            return true
        }
        return false
    }

    const validateTop3IsSet = () => {
        if (
            game?.teams?.some(team => team.position > 0) &&
            game?.teams?.find(team => team.position === 1)?.team ===
                firstPlace &&
            game?.teams?.find(team => team.position === 2)?.team ===
                secondPlace &&
            game?.teams?.find(team => team.position === 3)?.team === thirdPlace
        ) {
            return true
        }
        return false
    }

    const lockInTop3 = () => {
        const newTeams = game.teams.map(team => {
            if (team.team === firstPlace) {
                return {
                    ...team,
                    position: 1,
                }
            } else if (team.team === secondPlace) {
                return {
                    ...team,
                    position: 2,
                }
            } else if (team.team === thirdPlace) {
                return {
                    ...team,
                    position: 3,
                }
            }
            return team
        })

        useOneGameStore.getState().updateGame({
            teams: newTeams,
        })
    }

    const unlockTop3 = () => {
        setFirstPlace(null)
        setSecondPlace(null)
        setThirdPlace(null)

        const newTeams = game.teams.map(team => {
            if (team.position > 0) {
                return {
                    ...team,
                    position: 0,
                }
            }
            return team
        })
        useOneGameStore.getState().updateGame({
            teams: newTeams,
        })
    }

    return (
        <div className={`controls-panel ${showControls ? "show" : "hide"}`}>
            {useOneGameStore.getState().getCurrentRound() &&
                !useOneGameStore.getState().isEndSlide() && (
                    <ul
                        className={`game-information ${turnRoundIntoCSSCategoryName()}-background`}
                    >
                        <li className="game-info-item">
                            <span className="game-info-label">Round</span>
                            <span className="game-info-value">
                                {" "}
                                {useOneGameStore.getState().getCurrentRound()}
                            </span>
                        </li>
                        <li className="game-info-item">
                            <span className="game-info-value">
                                {" "}
                                {
                                    categories.find(
                                        c =>
                                            c.id ===
                                            game.rounds[
                                                useOneGameStore
                                                    .getState()
                                                    .getCurrentRound() - 1
                                            ]?.category
                                    )?.name
                                }
                                {categories.find(
                                    c =>
                                        c.id ===
                                        game.rounds[
                                            useOneGameStore
                                                .getState()
                                                .getCurrentRound() - 1
                                        ]?.category
                                )?.name === "Mystery" &&
                                    ` - ${template?.subCat}`}
                            </span>
                        </li>
                    </ul>
                )}
            <div className="button-row">
                {game.status === "created" || admin ? (
                    <button
                        className={`live-scoring-controls-button ${
                            showTeams ? "selected" : ""
                        }`}
                        onClick={() => {
                            setShowTeams(!showTeams)
                            setCurrentRound(0)
                            setCurrentTeam(null)
                            setShowScoreboard(false)
                            setShowControls(false)
                        }}
                    >
                        <PiUsersThreeFill />
                        <span className="controls-button-label">
                            Manage Teams ({game.teams.length})
                        </span>
                    </button>
                ) : (
                    <span></span>
                )}
                <button
                    className="live-scoring-controls-button show-scoreboard"
                    onClick={toggleScoreboard}
                >
                    {isScoreboardDisplayed ? <FaEyeSlash /> : <MdScoreboard />}
                    <span className="controls-button-label">
                        {isScoreboardDisplayed
                            ? "Hide Scoreboard"
                            : "Display Scoreboard"}
                    </span>
                </button>
                <button
                    className="live-scoring-controls-button copy-game"
                    onClick={() => setShowQR(true)}
                >
                    <QRCode
                        className="game-qr-button"
                        value={`${window.location.origin}/live-game/${game.id}/${game.template}`}
                        size={50}
                    />
                    <span className="controls-button-label">Game QR</span>
                </button>
            </div>

            <section className="host-controls">
                <h2 className="current-slide-label black-text">
                    {previousSlide === null
                        ? "Current Slide"
                        : "Viewing Scoreboard"}
                </h2>
                {previousSlide !== null && (
                    <>
                        <p className="viewing-scoreboard-description black-text">
                            You are currently viewing the scoreboard for the
                            game. You can toggle above or below to go back to
                            the host controls.
                        </p>
                        <button
                            className="back-to-controls"
                            onClick={toggleScoreboard}
                        >
                            Hide Scoreboard / Back to Controls
                        </button>
                    </>
                )}
                {/* previous button, current slide, next button */}
                <section className="slide-controls">
                    {previousSlide === null && (
                        <div className="slide-dropdown">
                            <select
                                onChange={handleSlideDropdownChange}
                                value={game.currentSlide}
                            >
                                {useOneGameStore
                                    .getState()
                                    .buildSlideDropdown(categories)
                                    .filter((slide, index) => index !== 96)
                                    .map((slide, index) => (
                                        <option key={index} value={slide.value}>
                                            {Number(slide.value) + 1} -{" "}
                                            {processDropDowntitle(slide.label)}
                                        </option>
                                    ))}
                            </select>
                            <TbHelpHexagonFilled
                                className="help-toggle"
                                onClick={() => setShowHelp(!showHelp)}
                            />
                        </div>
                    )}
                    {game?.currentSlide < 1 && (
                        <>
                            {showHelp && (
                                <p
                                    className="black-text next-slide-preview-text help-text"
                                    onClick={() => setShowHelp(!showHelp)}
                                >
                                    Start the game by clicking the slideshow
                                    button.
                                </p>
                            )}
                            <button
                                className={`scoreboard-button selected ${
                                    game?.opened ? "opened" : "never-opened"
                                }`}
                            >
                                <a
                                    className="slideshow-button"
                                    target="_blank"
                                    href={`/slideshow/${game.id}`}
                                    onClick={() =>
                                        handledGameOpenedForFirstTime()
                                    }
                                    rel="noreferrer"
                                >
                                    <RiSlideshow2Fill /> <span>Slideshow</span>
                                </a>
                            </button>
                        </>
                    )}

                    {game?.currentSlide > 0 &&
                        game?.currentSlide < 4 &&
                        showHelp && (
                            <p
                                className="black-text next-slide-preview-text help-text"
                                onClick={() => setShowHelp(!showHelp)}
                            >
                                These are instructions for the players on how to
                                play the game, the rules and how to track stats
                                on the website. This is your help box. Click the
                                ? button above at anytime to show/hide this box.
                            </p>
                        )}

                    {game?.currentSlide === 4 && showHelp && (
                        <p
                            className="black-text next-slide-preview-text help-text"
                            onClick={() => setShowHelp(!showHelp)}
                        >
                            Now, make sure you've got your teams ready to play.
                            You need at least one to proceed. You can also
                            dismiss this box by clicking directly on it
                        </p>
                    )}

                    {game?.currentSlide === 1 && (
                        <div className="black-text next-slide-preview-text">
                            <h4 className="how-it-works-title">How it works</h4>
                            <ul className="how-it-works-list">
                                <li>
                                    Seven rounds of intense trivia competition
                                </li>
                                <li>
                                    The rounds will be presented as a choice
                                    between History, Pop Culture, Science,
                                    Places, Sports, Movies, Music, and a Mystery
                                    category.
                                </li>
                                <li>
                                    All correct answers are worth 1 point each
                                </li>
                                <li>
                                    If you miss a question, don’t worry! They
                                    will continue to cycle on the screen!
                                </li>
                            </ul>
                        </div>
                    )}

                    {game?.currentSlide === 2 && (
                        <div className="black-text next-slide-preview-text">
                            <h4 className="how-it-works-title">
                                How it works (cont)
                            </h4>
                            <ul className="how-it-works-list">
                                <li>
                                    Answer sheets are provided for you,
                                    collaborate with your team during the
                                    allotted time per round to arrive at your
                                    answer, then turn in your answer sheet.
                                </li>
                                <li>
                                    Please, no outside materials are to be used
                                    during trivia rounds. To maintain the
                                    integrity of the game and ensure a fun time
                                    for all players, phones, textbooks,
                                    notebooks, etc. are strictly forbidden
                                    during trivia rounds.
                                </li>
                                <li>
                                    The trivia host is always right, and all of
                                    my decisions are final.
                                </li>
                            </ul>
                        </div>
                    )}

                    {game?.currentSlide === 3 && (
                        <div className="black-text next-slide-preview-text">
                            <h4 className="how-it-works-title">
                                Website Stats
                            </h4>
                            <ul className="how-it-works-list">
                                <li>
                                    Sign in your team, join a team or create a
                                    team to play and track your stats.
                                </li>
                                <li>
                                    Scan the QR code to the right to join the
                                    game.
                                </li>
                                <li>
                                    If you miss this opportunity, be sure to see
                                    the host after the game to track your users
                                    stats.
                                </li>
                            </ul>
                        </div>
                    )}
                    {game?.currentSlide === 4 && (
                        <div className="black-text next-slide-preview-text">
                            <h4 className="how-it-works-title">
                                Trivia Starts Now
                            </h4>
                        </div>
                    )}
                    {game?.currentSlide === 5 && (
                        <div className="black-text next-slide-preview-text">
                            <h4 className="how-it-works-title">
                                Lowest Scoring Team selects, click team name to
                                randomize to another team
                            </h4>
                        </div>
                    )}
                    {game?.currentSlide === 6 && (
                        <div className="black-text next-slide-preview-text">
                            <h4 className="how-it-works-title">
                                Start of Round
                            </h4>
                        </div>
                    )}

                    {useOneGameStore(state =>
                        state.isCategorySelectionSlide()
                    ) &&
                        showHelp && (
                            <p
                                className="black-text next-slide-preview-text help-text"
                                onClick={() => setShowHelp(!showHelp)}
                            >
                                The team with the lowest score (who hasn't
                                picked yet), has been chosen to select a
                                category. Let them choose and then click the
                                category they chose below. Click team name to
                                select a different team.
                            </p>
                        )}
                    {useOneGameStore(state => state.isCategorySlide()) &&
                        showHelp && (
                            <p
                                className="black-text next-slide-preview-text help-text"
                                onClick={() => setShowHelp(!showHelp)}
                            >
                                We are now showing the players the current
                                category. Click next to start the round with
                                Question 1.
                            </p>
                        )}
                    {useOneGameStore(state => state.isRotatingSlide()) &&
                        showHelp && (
                            <p
                                className="black-text next-slide-preview-text help-text"
                                onClick={() => setShowHelp(!showHelp)}
                            >
                                It's time to score each team! While you are
                                doing that, the entire round's questions will be
                                rotating, one every 30 seconds. Once you're done
                                scoring, proceed to the Answers slide.
                            </p>
                        )}
                    {useOneGameStore(state => state.isAnswerSlide()) &&
                        showHelp && (
                            <p
                                className="black-text next-slide-preview-text help-text"
                                onClick={() => setShowHelp(!showHelp)}
                            >
                                Now we can step through each question, and each
                                answer. One at a time. Clicking the next button
                                will either reveal the answer to the players or
                                move to the next question. After the last
                                question proceed to the Scoreboard.
                            </p>
                        )}
                    {useOneGameStore(state => state.isEndSlide()) &&
                        showHelp && (
                            <p
                                className="black-text next-slide-preview-text help-text"
                                onClick={() => setShowHelp(!showHelp)}
                            >
                                You've reached the end of the game. Once you're
                                done scoring every team you can Finalize the
                                Scores.
                            </p>
                        )}
                    {useOneGameStore(state => state.isEndSlide()) && (
                        <div className="tie-breakers">
                            <h3 className="tie-breaker-title">
                                Tie Breaker Required
                            </h3>
                            <p className="tie-breaker-message">
                                Please ask the following question to the teams
                                tied for first. Whoever is closest wins the
                                venue prize. This has zero effect on the final
                                scores.
                            </p>
                            <p className="tie-breaker-question">
                                {template?.tiebreakerQuestion}
                            </p>
                            <p className="tie-breaker-answer">
                                {template?.tiebreakerAnswer}
                            </p>
                        </div>
                    )}
                    {useOneGameStore(state => state.isScoreboardSlide()) && (
                        <>
                            {showHelp && (
                                <p
                                    className="black-text next-slide-preview-text help-text"
                                    onClick={() => setShowHelp(!showHelp)}
                                >
                                    The scoreboard is now showing. Feel free to
                                    review the scores with players.
                                </p>
                            )}
                            {/* I want to do a condensed version of the scoreboard here. Simple ul, li, team displayName or name - Round: 0 - Total: 0 - sorted by highest total */}
                            {checkTopThreeForTie() && (
                                <p className="black-text next-slide-preview-text top-3-message">
                                    Tiebreaking will be handled at the end of
                                    the game.
                                </p>
                            )}
                            <ul className="scoreboard-preview">
                                <li>
                                    <span className="team-name">Team</span>
                                    <span className="team-round">Round</span>
                                    <span className="team-total">Total</span>
                                </li>
                            </ul>
                            <ol
                                className={`scoreboard-preview ${
                                    showHelp && "showing-help"
                                }`}
                            >
                                {game?.teams
                                    .map((team, index, origArray) => {
                                        return {
                                            ...team,
                                            total: team.scores.reduce(
                                                (acc, score) =>
                                                    acc +
                                                    score.questions.filter(
                                                        q => q.correct
                                                    ).length,
                                                0
                                            ),
                                            rank:
                                                origArray.filter(
                                                    t =>
                                                        t.scores.reduce(
                                                            (acc, score) =>
                                                                acc +
                                                                score.questions.filter(
                                                                    q =>
                                                                        q.correct
                                                                ).length,
                                                            0
                                                        ) >
                                                        team.scores.reduce(
                                                            (acc, score) =>
                                                                acc +
                                                                score.questions.filter(
                                                                    q =>
                                                                        q.correct
                                                                ).length,
                                                            0
                                                        )
                                                ).length + 1,
                                        }
                                    })
                                    .sort((a, b) => b.total - a.total)
                                    .map((team, index) => {
                                        return (
                                            <li key={team.team}>
                                                <span className="team-name">
                                                    {team.rank} -{" "}
                                                    {team.displayName ||
                                                        teams.find(
                                                            t =>
                                                                t.id ===
                                                                team.team
                                                        )?.name ||
                                                        "Team"}{" "}
                                                </span>
                                                <span className="team-round">
                                                    {team.scores
                                                        .find(
                                                            s =>
                                                                s.round ===
                                                                currentRoundOne
                                                        )
                                                        ?.questions.filter(
                                                            q => q.correct
                                                        ).length || 0}
                                                </span>
                                                <span className="team-total">
                                                    {team.total}
                                                </span>
                                            </li>
                                        )
                                    })}
                            </ol>
                        </>
                    )}

                    {useOneGameStore(state => state.isQuestionSlide()) &&
                        showHelp && (
                            <p
                                className="black-text next-slide-preview-text help-text"
                                onClick={() => setShowHelp(!showHelp)}
                            >
                                This is a question slide, the current question
                                is now showing. After enough time has passed,
                                click the Next button to cycle through all 8
                                questions for this round. Then proceed to the
                                Scoring / Rotating slide.
                            </p>
                        )}

                    {getCurrentSlidePreview() !== "" && (
                        <p className="black-text next-slide-preview-text">
                            {getCurrentSlidePreview()}
                        </p>
                    )}

                    {!useOneGameStore(state =>
                        state.isCategorySelectionSlide()
                    ) &&
                        previousSlide === null && (
                            <div className="slide-buttons">
                                <button
                                    className="prev-slide"
                                    onClick={handlePrevSlide}
                                    disabled={game.currentSlide === 0}
                                >
                                    <span className="next-prev-slide-label">
                                        <BsFillRewindFill />
                                    </span>
                                </button>
                                <button
                                    disabled={
                                        (game.currentSlide ===
                                            useOneGameStore
                                                .getState()
                                                .lastSlide() &&
                                            game.status === "finalized") ||
                                        useOneGameStore
                                            .getState()
                                            .determineDisabled()
                                    }
                                    className="next-slide"
                                    onClick={handleNextSlide}
                                >
                                    <span className="next-prev-slide-label">
                                        <BsFillPlayFill />
                                    </span>
                                    <span className="next-prev-slide-title">
                                        {getNextButtonLabel()}
                                    </span>
                                </button>
                            </div>
                        )}
                </section>
            </section>
            {showQR && (
                <Modal
                    title="Game QR Code"
                    className="add-team-modal"
                    onClose={() => setShowQR(false)}
                >
                    <QRCode
                        className="game-qr-button"
                        value={`${window.location.origin}/live-game/${game.id}/${game.template}`}
                        size={500}
                    />
                    <p className="qr-instructions">
                        <Link
                            to={`/live-game/${game.id}/${game.template}`}
                            target="_blank"
                        >
                            Live Game Page
                        </Link>
                    </p>
                </Modal>
            )}
            {useOneGameStore(state => state.isCategorySelectionSlide()) && (
                <CategorySelection
                    view="liveScoring"
                    handleCategoryClick={handleCategoryClick}
                    handleTeamClick={() => {
                        calculateChoosingTeam(0)
                    }}
                />
            )}
            {useOneGameStore(state => state.currentSlideTitle()) ===
                "Rotating" && (
                <button
                    className="time-to-score-button"
                    onClick={() => {
                        setShowControls(false)
                        setShowTeams(false)
                        const cuirrentRound = useOneGameStore
                            .getState()
                            .getCurrentRound()
                        processCurrentRound(cuirrentRound)
                    }}
                >
                    Time to Score
                </button>
            )}
        </div>
    )
}
export default ControlsPanel
