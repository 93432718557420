import create from "zustand"
import dataService from "./dataService"
import moment from "moment"

const blocks = [
    {
        id: 1,
        start: "00:00",
        end: "02:59",
        name: "midnight",
    },
    {
        id: 2,
        start: "03:00",
        end: "05:59",
        name: "early-morning",
    },
    {
        id: 3,
        start: "06:00",
        end: "08:59",
        name: "breakfast",
    },
    {
        id: 4,
        start: "09:00",
        end: "11:59",
        name: "morning",
    },
    {
        id: 5,
        start: "12:00",
        end: "14:59",
        name: "lunch",
    },
    {
        id: 6,
        start: "15:00",
        end: "17:59",
        name: "afternoon",
    },
    {
        id: 7,
        start: "18:00",
        end: "21:00",
        name: "primetime",
    },
    {
        id: 8,
        start: "21:00",
        end: "23:59",
        name: "late-night",
    },
]

const useTemplateStore = create((set, get) => ({
    templateID: false,
    template: {},
    questions: [],
    scores: [],
    subCat: "",
    seasonalTemplates: [],
    getTemplate: async templateID => {
        await set({
            templateID: templateID,
            template: {},
            questions: [],
            scores: [],
        })
        dataService
            .getFromWhere("templates", "__name__", templateID)
            .then(data => {
                set({
                    templateID: templateID,
                    template: data[0],
                    subCat: data[0]?.subCat,
                })
            })
    },
    getTemplatesForSeason: async seasonID => {
        await dataService
            .getData("templates", () => {})
            .then(templates => {
                const seasonalTemplates = templates.filter(
                    template => template.season === seasonID
                )
                set({ seasonalTemplates })
                return seasonalTemplates
            })
    },
    findTemplateForDate: date => {
        return dataService
            .getData("templates", () => {})
            .then(templates =>
                templates.find(
                    t =>
                        moment(t.date).format("YYYY-MM-DD") ===
                        moment(date).format("YYYY-MM-DD")
                )
            )
    },
    findTemplateForDateAndTime: (date, time) => {
        return dataService
            .getData("templates", () => {})
            .then(templates =>
                templates.find(
                    t =>
                        moment(t.date).format("YYYY-MM-DD") ===
                            moment(date).format("YYYY-MM-DD") &&
                        t.block ===
                            blocks.find(b => b.start >= time && b.end <= time)
                                .id
                )
            )
    },
    createQuestion: question => {
        dataService
            .createQuestion({
                question: question.question,
                answer: question.answer,
                category: question.category,
            })
            .then(data => {
                set(state => ({
                    questions: [...state.questions, data],
                }))
            })
    },
    updateQuestion: question => {
        dataService
            .updateQuestion({
                id: question.questionId,
                question: question.question,
                answer: question.answer,
                category: question.category,
            })
            .then(data => {
                set(state => ({
                    questions: state.questions.map(q =>
                        q.id === data.id ? data : q
                    ),
                }))
            })
    },
}))

export default useTemplateStore
