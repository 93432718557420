import React, { useState } from "react"
import { Autocomplete } from "@react-google-maps/api"
import dataService from "../../data/dataService"
import useVenueStore from "../../data/venueStore"
import moment from "moment"

const AddVenueForm = ({ toggleAddVenueForm, selectedDate }) => {
    const [searchResults, setSearchResults] = useState([])
    const [autocomplete, setAutocomplete] = useState(null)
    const [selectedResult, setSelectedResult] = useState(null)
    const [selectedPhoto, setSelectedPhoto] = useState(null)
    const [error, setError] = useState(null)

    const handleLoad = ref => {
        setAutocomplete(ref)
        if (ref) {
            ref.setComponentRestrictions({ country: "us" })
        }
    }

    const handlePlaceChanged = () => {
        setError(null)
        if (!autocomplete) {
            console.error("Autocomplete not loaded properly.")
            return
        }

        const place = autocomplete?.getPlace()

        if (!place || !place.geometry) {
            setError(
                "No valid place selected. Please select a place from the dropdown."
            )
            return
        }
        const selectedVenue = {
            name: place.name,
            website: place.website || place.url,
            address: place.formatted_address,
            city: place.address_components.find(comp =>
                comp.types.includes("locality")
            ).long_name,
            state: place.address_components.find(comp =>
                comp.types.includes("administrative_area_level_1")
            ).short_name,
            zip: place.address_components.find(comp =>
                comp.types.includes("postal_code")
            ).long_name,
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            photos: place.photos
                ? place.photos.map(photo => photo.getUrl())
                : [],
            day: moment(selectedDate).format("dddd"), // Tuesday, Wednesday,Thursday
            time: "7pm",
        }
        setSearchResults([selectedVenue])
        // Save the selectedVenue to your database or state
    }

    const addVenueToDatabase = () => {
        dataService.insertIntoCollection("venues", {
            ...selectedResult,
            photo: selectedPhoto,
        })
        useVenueStore.getState().getVenues()
        setSearchResults([])
        setSelectedResult(null)
        setSelectedPhoto(null)
        setError(null)
        setAutocomplete(null)
        toggleAddVenueForm()
    }

    return (
        <>
            <Autocomplete
                onLoad={handleLoad}
                onPlaceChanged={handlePlaceChanged}
                className="add-venue-autocomplete"
            >
                <input
                    type="text"
                    placeholder="Search for a venue"
                    className="add-venue-search"
                    autoFocus
                />
            </Autocomplete>
            <div className="search-results">
                <h2 className="search-results-title">
                    {searchResults.length === 0
                        ? "Search for a local business, results will be displayed here"
                        : "Select the right venue by choosing from the search result"}{" "}
                </h2>
                {error && <p className="error">{error}</p>}
                {searchResults.map(venue => (
                    <div
                        key={venue.name}
                        className={`search-result ${
                            selectedResult?.name === venue.name
                                ? "selected"
                                : ""
                        }`}
                        onClick={() => setSelectedResult(venue)}
                    >
                        <h3>{venue.name}</h3>
                        <p>{venue.address}</p>
                        <p>
                            {venue.city}, {venue.state} {venue.zip}
                        </p>
                        <p>{venue.website}</p>
                        {selectedResult?.name === venue.name && (
                            <div className="search-result-photos">
                                <h3 className="search-result-photos-title">
                                    Now Select a Key Photo for the Venue (please
                                    be appropriate and respectful)
                                </h3>
                                <ul className="search-result-photos-list">
                                    {venue.photos?.map((photo, i) => (
                                        <li
                                            key={i}
                                            className="search-result-photos-list-item"
                                        >
                                            <img
                                                key={i}
                                                src={photo}
                                                alt={venue.name}
                                                height={100}
                                                className={`search-result-photo
                                                ${
                                                    selectedPhoto === photo
                                                        ? "selected"
                                                        : ""
                                                }`}
                                                onClick={() =>
                                                    setSelectedPhoto(photo)
                                                }
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {selectedResult?.name === venue.name &&
                            selectedPhoto !== null && (
                                <button
                                    className="search-result-save"
                                    onClick={() => {
                                        addVenueToDatabase()
                                    }}
                                >
                                    Add Venue & Photo
                                </button>
                            )}
                    </div>
                ))}
            </div>
        </>
    )
}

export default AddVenueForm
