import React, { useEffect } from "react"
import moment from "moment"
import { Link } from "react-router-dom"

import { FaPencilAlt } from "react-icons/fa" // Import pencil icon

import useVenueStore from "../../data/venueStore"
import useUserStore from "../../data/userStore"
import dataService from "../../data/dataService"

export default function CalendarBody({
    buttonData,
    buttonItem,
    data,
    setData,
    days,
    buttonClickAdd,
    buttonClickEdit,
    date,
    view,
    gameCredits = 0,
    handleEditGame = () => {},
}) {
    const deleteGame = async id => {
        dataService.deleteGame(id)
        setData(data.filter(game => game.id !== id))
    }

    const getDayOfWeek = day => {
        if (!day || !day.lll) return -1
        return moment(day.lll, "MMM D, YYYY h:mm A").day()
    }

    return (
        <ul className="calendar__week-bottom">
            {view === "monthly" &&
                Array.from({ length: getDayOfWeek(days[0]) }).map(
                    (_, index) => (
                        <li
                            key={`spacer-${index}`}
                            className="calendar__week-bottom-item"
                        ></li>
                    )
                )}
            {days.map(day => (
                <li
                    key={day.lll}
                    className={`${
                        moment().format("ll") === day.ll
                            ? "calendar__week-bottom-item calendar__week-bottom-item--today"
                            : "calendar__week-bottom-item"
                    }`}
                >
                    <span className="month-date">{day.date}</span>
                    {view === "weekly" &&
                        (buttonData.find(
                            bd =>
                                bd.date === moment(day.lll).format("YYYY-MM-DD")
                        ) ? (
                            <button
                                className="calendar__week-bottom-template calendar__week-bottom-template-edit"
                                onClick={() => {
                                    buttonClickEdit({
                                        day: day.lll,
                                        id: buttonData.find(
                                            bd =>
                                                bd.date ===
                                                moment(day.lll).format(
                                                    "YYYY-MM-DD"
                                                )
                                        ).id,
                                    })
                                }}
                            >
                                Edit Template
                            </button>
                        ) : (
                            <button
                                className="calendar__week-bottom-template calendar__week-bottom_template-add"
                                onClick={() => buttonClickAdd(day.lll)}
                            >
                                Add Template
                            </button>
                        ))}
                    {view === "host-dashboard" &&
                        data.filter(
                            item =>
                                (view === "monthly" &&
                                    item.date.format("ll") === day.ll) ||
                                (view !== "monthly" &&
                                    moment(item.date).format("ll") ===
                                        day.ll) ||
                                (view === "monthly" && item.day === day.day)
                        ).length === 0 && (
                            <button
                                className="calendar__week-bottom-template calendar__week-bottom-template-edit calendar__week-bottom-template-add--game"
                                onClick={() => buttonClickEdit(day.lll)}
                                disabled={gameCredits < 1}
                            >
                                Schedule Game
                            </button>
                        )}

                    {data.filter(
                        item =>
                            (view === "monthly" &&
                                item.date.format("ll") === day.ll) ||
                            (view !== "monthly" &&
                                moment(item.date).format("ll") === day.ll)
                    ).length > 0 ? (
                        <ul className="calendar-event-games">
                            {data
                                .filter(
                                    item =>
                                        (view === "monthly" &&
                                            item.date.format("ll") ===
                                                day.ll) ||
                                        (view !== "monthly" &&
                                            moment(item.date).format("ll") ===
                                                day.ll) ||
                                        (view === "monthly" &&
                                            item.day === day.day)
                                )
                                .map(item => (
                                    <li
                                        className={`calendar-event-game calendar-event-game-view-${view} calendar-event-game-status-${
                                            item.status
                                        } ${
                                            item.template === "" &&
                                            "missing-template"
                                        } ${
                                            moment().format("ll") === day.ll
                                                ? "game-today"
                                                : ""
                                        }`}
                                        key={item.id}
                                        onClick={() =>
                                            view === "host-dashboard" &&
                                            item.template !== "" &&
                                            buttonClickEdit(day.lll)
                                        }
                                    >
                                        {view === "monthly" && (
                                            <>
                                                <span className="calendar-event-game__venue">
                                                    {item.name}
                                                </span>
                                                <span className="calendar-event-game__time">
                                                    {item.time}
                                                </span>
                                            </>
                                        )}
                                        {view === "host-dashboard" && (
                                            <>
                                                <span
                                                    className={`calendar-event-game__edit enabled-${
                                                        item.teams?.length === 0
                                                    }`}
                                                    onClick={e =>
                                                        handleEditGame(item, e)
                                                    }
                                                >
                                                    <FaPencilAlt />
                                                </span>
                                                <span
                                                    className="calendar-event-game__venue
                                                "
                                                >
                                                    {
                                                        useVenueStore
                                                            .getState()
                                                            .venues.find(
                                                                venue =>
                                                                    venue.id ===
                                                                    item.venue
                                                            )?.name
                                                    }
                                                </span>
                                                <span className="calendar-event-game__time">
                                                    {item.time
                                                        ? moment(
                                                              item.time,
                                                              "HH:mm"
                                                          ).format("h:mm A")
                                                        : useVenueStore
                                                              .getState()
                                                              .venues.find(
                                                                  venue =>
                                                                      venue.id ===
                                                                      item.venue
                                                              )?.time}
                                                </span>
                                                {item.visibility ===
                                                    "private" && (
                                                    <span className="calendar-event-game__visibility">
                                                        Private
                                                    </span>
                                                )}
                                                <span className="calendar-event-game__teams">
                                                    {item.template === ""
                                                        ? "Game Template Coming Soon"
                                                        : `Teams: ${item.teams?.length}`}
                                                </span>
                                                {/* <button
                                                    className="calendar-event-game__delete"
                                                    onClick={() =>
                                                        deleteGame(item.id)
                                                    }
                                                >
                                                    Delete
                                                </button> */}
                                            </>
                                        )}
                                        {view === "weekly" && (
                                            <>
                                                <Link to={`/game/${item.id}`}>
                                                    <span className="calendar-event-game__venue">
                                                        {
                                                            useVenueStore
                                                                .getState()
                                                                .venues.find(
                                                                    venue =>
                                                                        venue.id ===
                                                                        item.venue
                                                                )?.name
                                                        }
                                                    </span>

                                                    <span className="calendar-event-game__host">
                                                        {
                                                            useUserStore
                                                                .getState()
                                                                .hosts.find(
                                                                    user =>
                                                                        user.uid ===
                                                                        item.user
                                                                )?.name
                                                        }
                                                    </span>
                                                    <span className="calendar-event-game__time">
                                                        {moment(
                                                            item.time,
                                                            "HH:mm"
                                                        ).format("h:mm A")}
                                                    </span>
                                                    <span className="calendar-event-game__template">
                                                        {item.template}
                                                    </span>
                                                </Link>

                                                <button
                                                    className="calendar-event-game__delete"
                                                    onClick={() => {
                                                        if (
                                                            window.confirm(
                                                                "Are you sure you want to delete this game?"
                                                            )
                                                        ) {
                                                            deleteGame(item.id)
                                                        }
                                                    }}
                                                >
                                                    Delete
                                                </button>
                                            </>
                                        )}
                                    </li>
                                ))}
                        </ul>
                    ) : (
                        view === "monthly" && <p>No Games</p>
                    )}
                </li>
            ))}
        </ul>
    )
}
