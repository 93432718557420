import React, { useEffect, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth, db } from "../../firebase"
import { addDoc, collection, doc, updateDoc } from "firebase/firestore"
import { useNavigate } from "react-router-dom"
import moment from "moment"

import { IoArrowBackCircle } from "react-icons/io5"

import dataService from "../../data/dataService"

import "./GameTemplates.css"
import Calendar from "../../components/Calendar/Calendar"
import useCategoryStore from "../../data/categoryStore"

import Modal from "../../components/Modal/Modal"
import GameTemplateForm from "../../components/Forms/GameTemplateForm"

export default function GameTemplates() {
    const [templates, setTemplates] = useState([])
    const [newTemplate, setNewTemplate] = useState(false)
    const [editTemplate, setEditTemplate] = useState(false)
    const [user] = useAuthState(auth)
    const [games, setGames] = useState([])
    const navigate = useNavigate()
    const [data, setData] = useState({})

    useEffect(() => {
        let isMounted = true

        dataService
            .getData("templates", () => {})
            .then(templates => {
                if (isMounted) {
                    setTemplates(templates)
                }
            })
        return () => {
            isMounted = false
        }
    }, [])

    const dateChanged = async date => {
        const startOfWeek = moment(date).startOf("week")
        const endOfWeek = moment(date).endOf("week")
        const games = await dataService.getGamesForTheWeek(
            startOfWeek,
            endOfWeek
        )
        setGames(games)
    }

    const addNewGameTemplate = async ({ data, keepEditing }) => {
        const blocks = [
            {
                id: 1,
                start: "00:00",
                end: "02:59",
                name: "midnight",
            },
            {
                id: 2,
                start: "03:00",
                end: "05:59",
                name: "early-morning",
            },
            {
                id: 3,
                start: "06:00",
                end: "08:59",
                name: "breakfast",
            },
            {
                id: 4,
                start: "09:00",
                end: "11:59",
                name: "morning",
            },
            {
                id: 5,
                start: "12:00",
                end: "14:59",
                name: "lunch",
            },
            {
                id: 6,
                start: "15:00",
                end: "17:59",
                name: "afternoon",
            },
            {
                id: 7,
                start: "18:00",
                end: "21:00",
                name: "primetime",
            },
            {
                id: 8,
                start: "21:00",
                end: "23:59",
                name: "late-night",
            },
        ]
        const questions = {}
        const { date, season, host } = data
        useCategoryStore.getState().categories.forEach(category => {
            questions[category.id] = Array.from({ length: 8 }, () => {
                return {
                    question: "",
                    answer: "",
                }
            })
        })

        /*
            blocks are a new concept where we are able to have multiple game templates on the same day, broken down into 8 blocks. Each block covers a 3 hour window.
            see the blocks constant above for the block names and their corresponding time windows
            this function will always use the primetime block
        */

        const newGameTemplate = {
            date,
            season,
            user: host,
            // questions is going to be an object, where the keys are the category IDs, and the values are an array of question obejcts which have a question and answer property
            questions,
            block: 7,
        }

        const newTemplateDoc = await addDoc(
            collection(db, "templates"),
            newGameTemplate
        )
        setNewTemplate(false)
        setTemplates([
            ...templates,
            { id: newTemplateDoc.id, ...newGameTemplate },
        ])
        // find every game that is on the same day as the new template and update the game's template property to the with the newTemplateDoc.id
        const gamesToUpdate = games.filter(
            game => moment(game.date).format("YYYY-MM-DD") === date
        )
        const batch = []
        gamesToUpdate.forEach(game => {
            const gameRef = doc(db, "games", game.id)
            batch.push(updateDoc(gameRef, { template: newTemplateDoc.id }))
        })
        await Promise.all(batch)
    }

    const updateGameTemplate = async ({ data, keepEditing }) => {
        // we don't need to add a doc, we need to update a doc
        const dataRef = doc(db, "templates", editTemplate.id)
        await updateDoc(dataRef, data)

        setTemplates([
            ...templates.filter(template => template.id !== editTemplate.id),
            { id: editTemplate.id, ...data },
        ])
        setData(data)
        if (!keepEditing) {
            setEditTemplate(false)
        }
    }

    return (
        <section className="game-templates-page">
            <header className="game-templates-page__header">
                <button
                    className="game-templates-page__back-btn"
                    onClick={() => navigate("/admin")}
                >
                    <IoArrowBackCircle />
                </button>
                <h1 className="game-templates-page__title">
                    Games & Templates
                </h1>
            </header>

            <Calendar
                buttonData={templates}
                buttonItem={editTemplate}
                data={games}
                setData={setGames}
                buttonClickAdd={setNewTemplate}
                buttonClickEdit={setEditTemplate}
                dateChanged={dateChanged}
                view="weekly"
            />

            {newTemplate && (
                <Modal
                    title="Add Game Template"
                    onClose={() => setNewTemplate(false)}
                >
                    <GameTemplateForm
                        onSubmit={addNewGameTemplate}
                        user={user}
                        selectedDate={moment(newTemplate).format("YYYY-MM-DD")}
                        action="add"
                    />
                </Modal>
            )}

            {editTemplate && (
                <Modal
                    title="Edit Game Template"
                    onClose={() => setEditTemplate(false)}
                >
                    <GameTemplateForm
                        onSubmit={updateGameTemplate}
                        user={user}
                        selectedDate={moment(editTemplate.day).format(
                            "YYYY-MM-DD"
                        )}
                        data={templates.find(
                            template => template.id === editTemplate.id
                        )}
                        action="edit"
                    />
                </Modal>
            )}
        </section>
    )
}
